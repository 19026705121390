<template>
    <div class="logisticsMap">
        <div id="map-chart" class="baiduMap"></div>
    </div>
</template>
<script>
import largeScreenFun from '../../JS';
import mapSkin from '../../JS/skin.js';
import echarts from 'echarts';
import axios from 'axios';
require('echarts/extension/bmap/bmap');
export default {
    name: 'logistics-map',
    components: {},
    props: {
        mapData: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            ak: '7FR14186sYk3MqjP9ggWxuHx1AQkRzZX',
            BMap: null,

            time: '',
            stationId: '',
            tenantId: '',

            mapSeries: [],
            mapCenter: [],

            mapChart: null,
        };
    },
    created() {},
    mounted() {
        this.$nextTick(function () {
            largeScreenFun.MP(this.ak).then(BMap => {
                this.BMap = BMap;
            });
            const dom = document.getElementById('map-chart');
            this.mapChart = echarts.init(dom);
        });
    },
    watch: {},
    methods: {
        async initPage() {
            this.mapSeries = [];
            this.mapCenter = [];
            this.time = this.$parent.time;
            this.stationId = this.$parent.stationId;
            this.tenantId = this.$parent.tenantId;
            this.logisticsLoading();
        },
        // 物流加载
        async handleMapData() {
            this.mapCenter = [115.53145, 35.243086];
            this.mapSeries = [];
            this.vehicleMarkingLoading();
            this.logisticsChartsLoading();
        },
        // 加载货源地标记
        siteTaLloading(newData) {
            newData = newData.filter(item => item.x_coordinate_point && item.x_coordinate_point);
            let symbolIcon = '';
            let symbolColor = '';
            if (newData.length > 0) {
                this.mapCenter = [newData[0].x_coordinate_point, newData[0].y_coordinate_point];
                newData.forEach(item => {
                    symbolColor = '#21BC9A';
                    symbolIcon = `image://${require('../../images/icon13.png')}`;
                    this.mapSeries.push(
                        {
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [{
                                name: item.name,
                                value: [item.x_coordinate_point, item.y_coordinate_point, 1],
                            }],
                            label: {
                                show: false,
                            },
                            symbol: symbolIcon,
                            symbolSize: 32,
                            symbolOffset: ['2%', '-50%'],
                            itemStyle: {
                                normal: {
                                    color: '#ea6347',
                                    borderWidth: 2,
                                    borderColor: '#f4b391',
                                },
                            },
                            zlevel: 2,
                        },
                        {
                            tooltip: {
                                show: false,
                            },
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            rippleEffect: {
                                scale: 10,
                                brushType: 'stroke',
                            },
                            showEffectOn: 'render',
                            label: {
                                formatter: '{b}',
                                position: 'bottom',
                                color: symbolColor,
                                fontSize: 12,
                                distance: 10,
                                show: true,
                            },
                            itemStyle: {
                                normal: {
                                    shadowColor: '#0ff',
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0,
                                    color: symbolColor,
                                },
                            },
                            symbol: 'circle',
                            symbolSize: [10, 5],
                            data: [{
                                name: item.name,
                                value: [item.x_coordinate_point, item.y_coordinate_point, 1],
                            }],
                            zlevel: 1,
                        },
                    );
                });
            } else {
                this.mapCenter = [116.404, 39.915];
            }
        },
        // 车辆标记加载
        vehicleMarkingLoading() {
            let symbolIcon = '';
            let symbolColor = '';
            if (this.mapData.vehicleData.length > 0) {
                this.mapData.vehicleData.forEach(item => {
                    if ([6].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(21, 188, 100, 0.3)';
                        symbolIcon = `image://${require('../../images/green.png')}`;
                    } else if ([77, 8].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(255, 126, 0, 0.3)';
                        symbolIcon = `image://${require('../../images/yellow.png')}`;
                    } else if ([7, 73].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(18, 77, 110, 0.3)';
                        symbolIcon = `image://${require('../../images/blue.png')}`;
                    } else if ([1, 2, 21, 3, 31, 4, 5].indexOf(item.status) > -1) {
                        symbolColor = 'rgba(255, 110, 119, 0.3)';
                        symbolIcon = `image://${require('../../images/red.png')}`;
                    } else {
                        symbolColor = 'rgba(21, 188, 100, 0.3)';
                        symbolIcon = `image://${require('../../images/green.png')}`;
                    }
                    this.mapSeries.push(
                        {
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [{
                                name: item.plate_small_number,
                                value: [item.longitude, item.latitude, 2],
                            }],
                            label: {
                                show: false,
                            },
                            symbol: symbolIcon,
                            symbolRotate: item.direction, // 车辆标记旋转角度
                            symbolSize: [20, 38],
                            // symbolKeepAspect: true,
                            itemStyle: {
                                normal: {
                                    color: '#ea6347',
                                    borderWidth: 2,
                                    borderColor: '#f4b391',
                                },
                            },
                            zlevel: 2,
                        },
                        // {
                        //     tooltip: {
                        //         show: false,
                        //     },
                        //     type: 'effectScatter',
                        //     coordinateSystem: 'bmap',
                        //     data: [ {
                        //         name: item.plate_number,
                        //         value: [item.longitude, item.latitude, 2],
                        //     }],
                        //     symbolSize: 36,
                        //     showEffectOn: 'render',
                        //     rippleEffect: {
                        //         brushType: 'stroke',
                        //     },
                        //     hoverAnimation: true,
                        //     label: {
                        //         formatter: '{b}',
                        //         position: 'bottom',
                        //         color: symbolColor,
                        //         fontSize: 12,
                        //         distance: 10,
                        //         show: true,
                        //     },
                        //     itemStyle: {
                        //         shadowBlur: 8,
                        //         shadowColor: symbolColor,
                        //         color: symbolColor,
                        //     },
                        //     zlevel: 1,
                        // },
                    );
                });
            }
        },
        // 物流加载
        logisticsChartsLoading() {
            // 飞线图标
            // eslint-disable-next-line max-len
            const planePath = 'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z';
            const flyingLineData = [];
            const siteData = [];

            this.mapData.siteLocation.forEach(item => {
                siteData.push({
                    type: 'scatter',
                    coordinateSystem: 'bmap',
                    data: [{
                        name: item.name,
                        value: [item.x_coordinate_point, item.y_coordinate_point, 1],
                    }],
                    label: {
                        show: false,
                    },
                    symbol: `image://${require('../../images/icon13.png')}`,
                    symbolSize: 28,
                    symbolOffset: ['2%', '-50%'],
                    itemStyle: {
                        normal: {
                            color: '#ea6347',
                            borderWidth: 2,
                            borderColor: '#f4b391',
                        },
                    },
                    zlevel: 3,
                }, {
                    tooltip: {
                        show: false,
                    },
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    rippleEffect: {
                        scale: 10,
                        brushType: 'stroke',
                    },
                    showEffectOn: 'render',
                    itemStyle: {
                        normal: {
                            shadowColor: '#0ff',
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            color: '#21BC9A',
                        },
                    },
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        color: '#01BE86',
                        fontSize: 12,
                        distance: 10,
                        show: true,
                    },
                    symbol: 'circle',
                    symbolSize: [10, 5],
                    data: [{
                        name: item.name,
                        value: [item.x_coordinate_point, item.y_coordinate_point, 1],
                    }],
                    zlevel: 2,
                });
                for (const station of this.mapData.stationLocation) {
                    if (station.station_id === item.station_id) {
                        flyingLineData.push({
                            fromName: station.name,
                            toName: item.name,
                            coords: [
                                [station.longitude, station.latitude],
                                [item.x_coordinate_point, item.y_coordinate_point],
                            ],
                        });
                        break;
                    }
                }
            });
            const stationData = this.mapData.stationLocation.map(station => {
                return {
                    name: station.name,
                    value: [station.longitude, station.latitude, 500],
                };
            });
            this.mapSeries.push(...siteData, {
                type: 'lines',
                coordinateSystem: 'bmap',
                zlevel: 2,
                effect: {
                    show: false,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 15,
                },
                lineStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(252, 239, 32, 0)',
                        }, {
                            offset: 1,
                            color: 'rgba(254, 189, 41, 1)',
                        }]),
                        width: 2,
                        opacity: 1,
                        curveness: 0.2,
                    },
                },
                data: flyingLineData,
            }, {
                type: 'scatter',
                coordinateSystem: 'bmap',
                data: stationData,
                symbol: `image://${require('../../images/icon22.png')}`,
                symbolSize: 80,
                itemStyle: {
                    normal: {
                        color: '#ea6347',
                        borderWidth: 2,
                        borderColor: '#f4b391',
                    },
                },
                label: {
                    formatter: '{b}',
                    position: 'bottom',
                    color: '#FEBD29',
                    fontSize: 10,
                    distance: -16,
                    show: true,
                },
                zlevel: 4,
            });
        },
        initChart() {
            const _that = this;
            this.time = this.$parent.time;
            const option = {
                bmap: {
                    center: this.mapCenter,
                    zoom: 10,
                    roam: true,
                    mapStyleV2: {
                        styleJson: mapSkin,
                    },
                    // mapStyle: {
                    //     styleJson: CUSTOM_MAP_CONFIG, // 地图样式配制
                    // },
                },
                series: this.mapSeries,
            };
            this.mapChart.setOption(option, true);
            const bmap = this.mapChart.getModel().getComponent('bmap').getBMap();


            this.mapChart.on('click', async function (params) {
                let newContent = '';
                let vehicleInfo = {};
                let opts = {};
                _that.$parent.loading = true;
                if (params.value[2] === 1) { // 货源地点击
                    const sourceData = _that.mapData.stationData.filter(item => item.name === params.name) || [];
                    let sourceId = '';
                    if (sourceData.length > 0) {
                        sourceId = sourceData[0].id;
                    }
                    if (sourceId) {
                        vehicleInfo = await _that.getSourceInfo(sourceId, _that.tenantId);
                    }
                } if (params.value[2] === 2) { // 车辆标记点击
                    const plate_number = params.name;
                    vehicleInfo = await largeScreenFun.getSupplyVehicleInfo(_that.time, plate_number, _that.stationId, _that.tenantId);
                }
                if (Object.keys(vehicleInfo).length > 0) {
                    if (params.value[2] === 1) { // 货源地标记点击
                        newContent = _that.loadConstructionSitePop(vehicleInfo);
                        opts = {
                            width: 350,
                            height: 350,
                        };
                    } if (params.value[2] === 2) { // 车辆标记点击
                        newContent = _that.loadVehiclePop(vehicleInfo);
                        opts = {
                            width: 350,
                            height: 230,
                        };
                    }
                    // eslint-disable-next-line no-undef
                    const infoWindow = new BMap.InfoWindow(newContent, opts);
                    // eslint-disable-next-line no-undef
                    const point = new BMap.Point(params.value[0], params.value[1]);
                    bmap.openInfoWindow(infoWindow, point); // 开启信息窗口
                } else {
                    _that.$message({
                        message: '暂无详细信息',
                        type: 'warning',
                    });
                }
                _that.$parent.loading = false;
            });
        },
        // 加载车辆Pop
        loadVehiclePop(vehicleInfo) {
            let newContent = '';
            newContent += '<ul class="mapPopStyle vehiclePop">';
            newContent += '<li class="vehiclePopHeader">';
            newContent += `<span class="licenseNumber iconfont iconTmpSVG">${vehicleInfo.plate_number}</span>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont icondriver">司机姓名：${vehicleInfo.driver_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconorder">发货单号：${vehicleInfo.no}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconchengshi">货源地名称：${vehicleInfo.source_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconyuancailiaojiagong">原材料规格名称：${vehicleInfo.spec_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconshijian">出厂时间：${vehicleInfo.tare_time}</p>`;
            newContent += '</li>';
            newContent += '</ul>';
            return newContent;
        },

        // 加载货源地Pop
        loadConstructionSitePop(vehicleInfo) {
            let newContent = '';
            newContent += '<ul class="mapPopStyle constructionSitePop">';
            newContent += '<li>';
            newContent += `<p class="iconfont iconweizhi">货源地：${vehicleInfo.name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconorder">货源地编号：${vehicleInfo.no}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconchengshi">货源地简称：${vehicleInfo.short_name}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconleibie">原材料分类：${vehicleInfo.material_names}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconweizhi">位置：${vehicleInfo.address}</p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconchengshi">管理企业：${vehicleInfo.org_name} </p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconnumber1">统一社会代码：${vehicleInfo.credit_code} </p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont iconyewuyuan">联系人：${vehicleInfo.leader} </p>`;
            newContent += '</li>';
            newContent += '<li>';
            newContent += `<p class="iconfont icondianhua">联系电话：${vehicleInfo.telephone}</p>`;
            newContent += '</li>';
            newContent += '</ul>';
            return newContent;
        },
        /**
         * @Description             获取工地信息
         * @param sourceId          货源地id
         * @param tenantId          租户id
         */
        getSourceInfo(sourceId, tenantId) {
            const path = `/interfaceApi/report/largescreen/get_source_info?id=${sourceId}&tenantId=${tenantId}`;
            return this.$axios.get(path, { defEx: true });
        },

        // 秒转天
        SecondToDate(msd) {
            let time = msd;
            if (msd) {
                if (msd > 3600) {
                    time = '一小时以上';
                } else if (msd <= 3600 && msd >= 60 ) {
                    time = '大约 ' + parseInt(time / 60.0, 0) + '分钟 以后';
                } else {
                    time = '一分钟以内';
                }
            } else {
                msd = '';
            }
            return time;
        },
    },
};
</script>
<style scoped></style>
